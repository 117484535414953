import {
  generateUniqueId,
  getLocalStorageMemoizedValue,
  urlJoin,
} from '@digital-spiders/misc-utils';
import { getHookPointStoredTrackingData } from '@hook-point/tracking-data';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { DEFAULT_SCHEDULE_ONCE_CALENDAR, ROOT_DOMAIN } from '../../constants';
import { PageDocument } from '../../types/types';
import { useAsyncEffect } from '../../utils/hooks';
import { useCloser } from '../../utils/projectUtils';
import { getPageDocumentUrl } from '../../utils/sanity';
import { withDataLayer } from '../../utils/utils';

export interface CalendarProps {
  pageToGoAfterSubmit?: PageDocument;
  className?: string;
}

function Calendar(props: CalendarProps): React.ReactElement {
  const { pageToGoAfterSubmit, className } = props;

  const [isCalendarDivReady, setIsCalendarDivReady] = useState<boolean>(false);

  const oncehubScheduleEventTriggered = useRef(false);

  const closer = useCloser();

  const [onceHubQueryParams, setOnceHubQueryParams] = useState<string | null>(null);
  const [onceHubCalendar, setOnceHubCalendar] = useState<string | null>(null);
  useAsyncEffect(async () => {
    if (closer === undefined) {
      return;
    }
    const trackingData = await getHookPointStoredTrackingData({
      rootDomain: ROOT_DOMAIN,
    });

    const filteredOnceHubData = Object.fromEntries(
      Object.entries({
        utm_source: trackingData.utmSource,
        utm_medium: trackingData.utmMedium,
        utm_campaign: trackingData.utmCampaign,
        utm_term: trackingData.utmTerm,
        utm_content: trackingData.utmContent,
        utm_device: trackingData.utmDevice,
        utm_target: trackingData.utmTarget,
        fbclid: trackingData.fbclid,
        gclid: trackingData.gclid,
        sourceUrl: trackingData.sourceUrl,
        referralSource: trackingData.referralSource,
        externalReferralSource: trackingData.externalReferralSource,
      }).filter(([_, value]) => value !== null),
    ) as Record<string, string>;

    setOnceHubQueryParams(new URLSearchParams(filteredOnceHubData).toString());
    setOnceHubCalendar(
      closer?.scheduleOnceCalendarId || trackingData?.socalendar || DEFAULT_SCHEDULE_ONCE_CALENDAR,
    );
  }, [closer]);

  useEffect(() => {
    if (onceHubQueryParams && onceHubCalendar) {
      window.addEventListener(
        'message',
        event => {
          if (
            event.origin === 'https://go.oncehub.com' &&
            event.data?.bookingData &&
            event.data?.bookingData?.errorStatus === 0 &&
            !oncehubScheduleEventTriggered.current
          ) {
            withDataLayer(dataLayer => {
              dataLayer.push({
                event: 'socalendar-schedule',
                calendar: onceHubCalendar,
                uniqueId: getLocalStorageMemoizedValue(
                  'randomly_generated_unique_id',
                  generateUniqueId,
                ),
              });
            });
            oncehubScheduleEventTriggered.current = true;
            window.location.href = urlJoin(
              pageToGoAfterSubmit ? getPageDocumentUrl(pageToGoAfterSubmit) : '',
            );
          }
        },
        false,
      );
      setIsCalendarDivReady(true);
    }
  }, [onceHubQueryParams, onceHubCalendar]);

  return (
    <>
      <Helmet>
        {isCalendarDivReady && (
          // OnceHub embed
          <script type="text/javascript" src="https://cdn.oncehub.com/mergedjs/so.js"></script>
        )}
      </Helmet>
      {onceHubQueryParams && onceHubCalendar && (
        <div
          id={'SOIDIV_' + onceHubCalendar}
          data-so-page={onceHubCalendar}
          data-height="550"
          data-style="border: 1px solid #D8D8D8; min-width: 290px; max-width: 900px;"
          data-psz="10"
          data-so-qry-prm={onceHubQueryParams}
          className={className}
        ></div>
      )}
    </>
  );
}

export default Calendar;
