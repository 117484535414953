/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import './src/translation/i18n';

import React from 'react';

import { TRACKING_DATA_SERVER_PATH } from '@digital-spiders/tracking-data';
import { storeHookPointTrackingData } from '@hook-point/tracking-data';
import { GatsbyBrowser } from 'gatsby';
import { ROOT_DOMAIN, SITE_DOMAIN } from './src/constants';
import { LocationContext } from './src/contexts/LocationContext';
import { ModalFormProvider } from './src/contexts/ModalFormContext';
import { NotificationsProvider } from './src/contexts/NotificationsContext';
import { URLParamsContextProvider } from './src/contexts/URLParamsContext';
import PreviewConnectorProvider from './src/preview/PreviewConnectorProvider';
import { PreviewStateProvider } from './src/preview/PreviewStateContext';
import { GlobalStateProvider } from './src/state/globalStateContext';

// You can delete this file if you're not using it
// export const onClientEntry = () => {
//     // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
//     if (!(`IntersectionObserver` in window)) {
//       import(`intersection-observer`);
//     }
//   };
// import { I18nextProvider } from 'react-i18next';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  // Do not wrap the TrackingDataServer component
  if (props.path.replaceAll(/(^\/|\/$)/g, '') === TRACKING_DATA_SERVER_PATH) {
    return element;
  }

  return (
    /* <I18nextProvider i18n={i18n}> */
    <GlobalStateProvider>
      <URLParamsContextProvider>
        <NotificationsProvider>
          <LocationContext.Provider value={props.location}>
            <ModalFormProvider>
              <PreviewStateProvider>
                <PreviewConnectorProvider>{element}</PreviewConnectorProvider>
              </PreviewStateProvider>
            </ModalFormProvider>
          </LocationContext.Provider>
        </NotificationsProvider>
      </URLParamsContextProvider>
    </GlobalStateProvider>
    /* </I18nextProvider> */
  );
};

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  storeHookPointTrackingData({
    currentDomain: SITE_DOMAIN,
    rootDomain: ROOT_DOMAIN,
  });
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
  }
};
