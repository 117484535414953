import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import { ButtonLinkType } from '../../graphql-fragments/ButtonLink';
import { NavigationMenu } from '../../graphql-fragments/navigationMenu';

import { removeParamsFromQueryParams, slugify } from '@digital-spiders/misc-utils';
import BlockContent from '@sanity/block-content-to-react';
import groq from 'groq';
import { FILE_QUERY_PARAM, NOAUTODOWNLOAD_QUERY_PARAM } from '../../constants';
import { LocationContext } from '../../contexts/LocationContext';
import { useNotifications } from '../../contexts/NotificationsContext';
import { useURLParamsContext } from '../../contexts/URLParamsContext';
import Logo from '../../images/logo.svg';
import whiteLogo from '../../images/logoWhite.svg';
import PreviewLoadingScreen from '../../preview/PreviewLoadingScreen';
import { usePreviewData } from '../../preview/previewUtils';
import { getSerializers } from '../../serializers';
import { RawPortableText } from '../../types/types';
import { ModuleBackgroundColor } from '../ModulesContent';
import Layout from './Layout';

interface SanityLayoutProps {
  children: React.ReactNode;
  pageTheme: ModuleBackgroundColor;
}

interface QueryData {
  sanityHpWebsiteSettings: {
    header: {
      navigationMenu: NavigationMenu;
      ctaButton: ButtonLinkType;
      ctaButtonAnchor?: string;
    };
    footer: {
      navigationLinks: Array<ButtonLinkType>;
      legalLinks: Array<ButtonLinkType>;
    };
  };
  sanityGetFileSettings: {
    downloadableFiles: Array<{
      fileParameterSlug: {
        current: string;
      };
      fileUrl: string;
    }>;
    _rawSuccessMessage: RawPortableText;
  };
}

const SanityLayout = ({ pageTheme, children }: SanityLayoutProps): React.ReactElement => {
  const staticData: QueryData = useStaticQuery(
    graphql`
      {
        sanityHpWebsiteSettings {
          header {
            navigationMenu {
              ...NavigationMenu
            }
            ctaButton {
              ...ButtonLink
            }
            ctaButtonAnchor
          }
          footer {
            navigationLinks {
              ...ButtonLink
            }
            legalLinks {
              ...ButtonLink
            }
          }
        }
        sanityGetFileSettings {
          downloadableFiles {
            fileParameterSlug {
              current
            }
            fileUrl
          }
          _rawSuccessMessage(resolveReferences: { maxDepth: 4 })
        }
      }
    `,
  );

  const groqQuery = groq`{
    "sanityHpWebsiteSettings": *[_id == "hp-website-settings-singleton"][0] {
      header {
        ...
      },
      footer {
        ...
      }
    },
    "sanityGetFileSettings": *[_id == "get-file-settings"][0] {
     ...
    }
  }`;

  const data = usePreviewData<QueryData>(staticData, {
    groqQuery,
  });

  const { ctaParam } = useURLParamsContext();

  if (!data) {
    return <PreviewLoadingScreen></PreviewLoadingScreen>;
  }

  const { navigationMenu, ctaButton, ctaButtonAnchor } = data.sanityHpWebsiteSettings.header;
  const { navigationLinks, legalLinks } = data.sanityHpWebsiteSettings.footer;

  const location = useContext(LocationContext);

  const { showNotification } = useNotifications();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const fileParamValue = urlSearchParams.get(FILE_QUERY_PARAM);
    const noAutoDownloadParamValue = urlSearchParams.get(NOAUTODOWNLOAD_QUERY_PARAM) !== null;

    if (fileParamValue || noAutoDownloadParamValue) {
      // Remove file and noautodownload query params
      removeParamsFromQueryParams([FILE_QUERY_PARAM, NOAUTODOWNLOAD_QUERY_PARAM]);

      if (fileParamValue) {
        const downloadableFile = data.sanityGetFileSettings.downloadableFiles.find(
          ({ fileParameterSlug }) => slugify(fileParameterSlug.current) === slugify(fileParamValue),
        );
        if (downloadableFile) {
          showNotification(
            <BlockContent
              renderContainerOnSingleChild
              blocks={data.sanityGetFileSettings._rawSuccessMessage}
              serializers={getSerializers(downloadableFile.fileUrl)}
            />,
          );
        }
      }
    }
  }, []);

  return (
    <Layout
      pageTheme={pageTheme}
      headerConfig={{
        sticky: true,
        logoImageUrl: Logo,
        entries: navigationMenu,
        ctaButton: ctaParam !== 'none' ? ctaButton : undefined,
        ctaButtonAnchor: ctaButtonAnchor,
        currentUrl: location?.pathname,
      }}
      footerConfig={{
        whiteLogoImageUrl: whiteLogo,
        navigationLinks: navigationLinks,
        legalLinks: legalLinks,
        newsletterBlock: {
          title: 'Subscribe to our newsletter',
        },
      }}
    >
      {children}
    </Layout>
  );
};

export default SanityLayout;
